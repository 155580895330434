/*
 * @Author: ChenYaJin
 * @Date: 2023-07-11 17:02:50
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-06-05 10:53:16
 * @Description:
 */
// type NonNullable<T> = T extends null | undefined ? never : T

/**
 * 表单配置项枚举
 */
export enum ETableItemType {
  TEXT, //文本
  INPUT, // 输入框
  SELECT, // 下拉单选
  DATE, //日期选择
  RADIO, // 单选
  UPLOAD_IMAGE, // 上传图片
  UPLOAD_FILE, // 上传文件
  ADDRESS, // 地址：省市区下拉选+详情地址
  ADDRESS_AREA, // 省市区下拉选
  ADDRESS_DETAIL, // 详情地址
}

export enum BusinessType {
  community = 'community',
  manager = 'manager',
  technician = 'technician',
  behavior = 'behavior',
}

export enum SnapshotType {
  enterprise = 'enterprise',
  community = 'community',
  manager = 'manager',
  technician = 'technician',
  behavior = 'behavior',
}

export enum BusinessTypeTitle {
  enterprise = '企业注册',
  community = '项目基本信息',
  manager = '项目经理信息',
  technician = '技术人员信息',
  behavior = '企业良好行为',
}

/**
 * 审核状态
 */
export enum AuditStatusEnum {
  APPROVED = 'approved',
  REVIEW = 'review',
  FAILED = 'failed',
}
export type AuditStatusType = NonNullable<'approved' | 'review' | 'failed'>
export const EAuditStatusType = {
  approved: '通过',
  review: '待审核',
  failed: '不通过',
}

/**
 * 项目性质
 */
export type ProjectType = NonNullable<'residence' | 'office' | ''>
export const EProjectType = {
  residence: '住宅',
  office: '非住宅',
}

/**
 * 员工类型
 */
export enum EmployeeType {
  MANAGER = 'manager',
  TECHNICIAN = 'technician',
}

/**
 * 入会申请上传文件类型
 */
export enum FileType {
  BUSINESS_LICENSE = 'businessLicense',
  CORPORATE_IDENTITY_CARD = 'corporateIdentityCard',
  INTEGRITY_RESPONSIBILITY_STATEMENT = 'integrityResponsibilityStatement',
}
